<template>
    <Modal v-model="showForm" title="客户标注" :mask-closable="false">
        <Form ref="markForm" :model="markForm" :rules="markFormRule" label-colon :label-width="90">
            <FormItem label="标注内容" prop="remark">
                <Input v-model.trim="markForm.remark" :maxlength="100" :autosize="{minRows: 2,maxRows: 5}" show-word-limit type="textarea" placeholder="请输入" />
            </FormItem>
        </Form>
        <div slot="footer">
            <Button type="default" @click="closeModal(false)">取消</Button>
            <Button type="primary" :loading="loading" @click="handleSubmit">确定</Button>
        </div>
    </Modal>
</template>
<script>
import { reqGetCustomerById, reqMarkCustomer} from "@/api/admin-api";

export default {
    name: "markCustomer",
    components: {},
    props: {
        value: {
            type: Boolean,
            default: false
        },
        id: '',
    },
    data() {
        return {
            loading: false,
            showForm: this.value,
            markForm: {
                remark: ''
            },
            markFormRule: {},
        }
    },
    methods: {
        handleSubmit() {
            this.$refs['markForm'].validate((valid) => {
                if (valid) {
                    this.loading = true;
                    reqMarkCustomer(this.markForm).then((res) => {
                        this.loading = false;
                        if (res.data.code === 1) {
                            this.$Message.success('标注成功');
                            this.$emit('getCustomer');
                            this.closeModal(false);
                        }
                    }).catch(() => {
                        this.loading = false;
                        this.$Message.error('标注失败');
                    });
                }
            });
        },
        getCustomerById() {
            reqGetCustomerById({id:this.id}).then((res)=>{
                this.markForm = res.data.data.customer
            }).catch(()=>{
                this.markForm = {}
            })
        },
        closeModal(val) {
            this.$emit('input', val);
        }
    },
    watch: {
        value(val) {
            this.showForm = val;
        },
        showForm(val) {
            //当重新显示增加数据的时候重置整个form表单
            if (val) {
                this.$refs['markForm'].resetFields();
                this.getCustomerById()
            } else {// 反之则关闭页面
                this.closeModal(val);
            }
        }
    }
}
</script>
