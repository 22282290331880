<template>
    <div class="main-content">
        <div class="breadcrumb">
            <Breadcrumb>
                <BreadcrumbItem>客户管理</BreadcrumbItem>
                <BreadcrumbItem>客户管理</BreadcrumbItem>
            </Breadcrumb>
        </div>
        <div class="filter">
            <Form label-colon inline>
                <FormItem class="ivu-form-item-xl">
                    <Input v-model="filter.keywords" placeholder="客户名称、客户联系人、负责人" clearable/>
                </FormItem>
                <FormItem>
                    <Select v-model="filter.create" placeholder="请选择创建人" filterable clearable>
                        <Option v-for="item in createArray" :value="item.id" :key="item.id">{{ item.name }}</Option>
                    </Select>
                </FormItem>
                <FormItem>
                    <DatePicker type="daterange" v-model="filter.customerDateRange" placeholder="请选择客户创建周期" separator=" 至 " clearable></DatePicker>
                </FormItem>
                <FormItem>
                    <treeselect v-model="filter.location"
                                :max-height="130"
                                :options="cityOpts"
                                :disable-branch-nodes="true"
                                placeholder="请选择所属地区"
                                loadingText="加载中..."
                                noOptionsText="暂无数据"
                                noResultsText="没有匹配数据"
                                clearable>
                    </treeselect>
                </FormItem>
                <FormItem>
                    <Select v-model="filter.source"  placeholder="请选择来源" filterable clearable label-in-value>
                        <Option v-for="item in sourceArray" :value="item.id" :key="item.id">{{ item.name }}</Option>
                    </Select>
                </FormItem>
                <FormItem>
                    <Button type="primary" @click="doSearching">搜索</Button>
                </FormItem>
                <FormItem>
                    <Button type="primary" @click="resetting">重置</Button>
                </FormItem>
                <FormItem>
                    <Button type="primary" icon="md-add" @click="handleAdd" v-if="permissionButton.add">新增</Button>
                </FormItem>
            </Form>
        </div>
        <div class="table">
            <div class="table-list">
                <Table stripe :columns="columns" :data="dataList" :loading="loading">
                    <template slot-scope="{ row, index }" slot="action">
                        <Dropdown transfer>
                            <a href="javascript:void(0)">操作<Icon type="ios-arrow-down"></Icon></a>
                            <DropdownMenu class="table-dropdown" slot="list">
                                <DropdownItem v-if="permissionButton.view" @click.native="handleView(row, index)">详情</DropdownItem>
                                <DropdownItem v-if="permissionButton.edit" @click.native="handleEdit(row, index)">编辑</DropdownItem>
                                <DropdownItem v-if="permissionButton.mark" @click.native="handleMark(row, index)">标注</DropdownItem>
                                <DropdownItem v-if="permissionButton.handover" @click.native="handleHandover(row, index)">交接</DropdownItem>
                                <DropdownItem v-if="permissionButton.followUp && row.userName === userName" @click.native="handleFollowUp(row, index)">跟进</DropdownItem>
                                <DropdownItem class="table-delete" v-if="permissionButton.del" @click.native="handleDel(row, index)">删除</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </template>
                </Table>
            </div>
            <div class="table-footer">
                <Row>
                    <Col class="total" span="6">共 {{ total }} 条记录 第 {{ pageNum }}/{{ pages }} 页</Col>
                    <Col class="pagenation" span="18">
                        <Page :page-size="pageSize" :total="total" :current="pageNum"
                              :page-size-opts="[15, 20, 50, 100]"
                              @on-change="changePage" @on-page-size-change="changPageSize" show-sizer show-elevator/>
                    </Col>
                </Row>
            </div>
        </div>
        <add-customer v-model="showAddModal" v-on:getCustomer="getCustomer"></add-customer>
        <edit-customer v-model="showEditModal" :id="id" v-on:getCustomer="getCustomer"></edit-customer>
        <view-customer v-model="showViewModal" :id="id"></view-customer>
        <follow-up v-model="showFollowUpModal" :id="id"></follow-up>
        <mark-customer v-model="showMarkModal" :id="id" v-on:getCustomer="getCustomer"></mark-customer>
        <handover-customer v-model="showHandoverModal" :ownerName="ownerName" :id="id" v-on:getCustomer="getCustomer"></handover-customer>
    </div>
</template>

<script>

import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import Treeselect from "@riophae/vue-treeselect";
import permission from '@/common/permission'
import {reqDeleteCustomer, reqGetCustomer} from "@/api/admin-api";
import addCustomer from './add'
import editCustomer from  './edit'
import viewCustomer from './view'
import markCustomer from './mark'
import handoverCustomer from './handover'
import followUp from './followUp'
import {reqGetDictionaryListByCode, reqGetUserList} from "@/api/system-api";
import city from "@/assets/area/city";

export default {
    name: 'resumeList',
    components: {
        viewCustomer,
        addCustomer,
        editCustomer,
        markCustomer,
        handoverCustomer,
        followUp,
        Treeselect
    },
    data() {
        return {
            sourceArray: [],
            createArray: [],
            id: '',
            userName: '',
            ownerName: '',
            showViewModal: false,
            showEditModal: false,
            showAddModal: false,
            showMarkModal: false,
            showHandoverModal: false,
            showFollowUpModal: false,
            filter: {
                keywords: '',
                location: null,
                source: null,
                customerDateRange: null,
                create: null
            },
            cityOpts: city,
            loading: false,
            dataList: [],
            permissionButton: {
                add: false,
                edit: false,
                view: false,
                del: false,
                mark: false,
                handover: false,
                followUp: false,
            },
            total: 0,
            pages: 1,
            pageSize: 20,
            pageNum: 1,
            columns: [
                {
                    title: '客户名称',
                    key: 'name',
                    tooltip: 'true',
                    minWidth: 300,
                },
                {
                    title: '所属地区',
                    key: 'locationName',
                    tooltip: 'ture',
                    minWidth: 120,
                },
                {
                    title: '联系地址',
                    key: 'address',
                    tooltip: 'ture',
                    minWidth: 300,
                },
                {
                    title: '负责人',
                    key: 'ownerName',
                    width: 120,
                },
                {
                    title: '标注',
                    key: 'remark',
                    tooltip: 'ture',
                    minWidth: 200,
                },
                {
                    title: '来源',
                    key: 'sourceName',
                    width: 100,
                },
                {
                    title: '商机数量',
                    key: 'countOfOpp',
                    width: 100,
                },
                {
                    title: '创建人',
                    key: 'createByName',
                    width: 120,
                },
                {
                    title: '更新时间',
                    key: 'updatedTime',
                    width: 180,
                },
                {
                    title: '操作',
                    slot: 'action',
                    width: 120,
                    align: 'center',
                    fixed: 'right',
                },
            ],
        }
    },
    methods: {
        getCustomer() {
            this.loading = true;
            this.dataList = [];
            let endTime =  ((this.filter.customerDateRange && this.filter.customerDateRange[1] !== '' && this.filter.customerDateRange.length > 1) ? this.$moment(this.filter.customerDateRange[1]).format('YYYY-MM-DD') : null);
            let startTime = ((this.filter.customerDateRange && this.filter.customerDateRange[0] !== '' && this.filter.customerDateRange.length > 0) ? this.$moment(this.filter.customerDateRange[0]).format('YYYY-MM-DD') : null);
            let para = {
                pageNum: this.pageNum,
                pageSize: this.pageSize,
                keywords: this.filter.keywords,
                source: this.filter.source,
                location: this.filter.location,
                create: this.filter.create,
                startTime: startTime,
                endTime: endTime
            };
            reqGetCustomer(para).then((res) => {
                this.loading = false;
                this.pageNum = res.data.data.current
                this.pages = res.data.data.pages
                this.total = res.data.data.total
                this.dataList = res.data.data.records;
            }).catch(() => {
                this.loading = false;
                this.dataList = [];
            });
        },
        resetting() {
            this.loading = true;
            this.dataList = [];
            this.filter.keywords= '';
            this.filter.location= null;
            this.filter.source= null;
            this.filter.customerDateRange= null;
            this.filter.create= null;
            let endTime =  ((this.filter.customerDateRange && this.filter.customerDateRange[1] !== '' && this.filter.customerDateRange.length > 1) ? this.$moment(this.filter.customerDateRange[1]).format('YYYY-MM-DD') : null);
            let startTime = ((this.filter.customerDateRange && this.filter.customerDateRange[0] !== '' && this.filter.customerDateRange.length > 0) ? this.$moment(this.filter.customerDateRange[0]).format('YYYY-MM-DD') : null);
            let para = {
                pageNum: 1,
                pageSize: 20,
                keywords: this.filter.keywords,
                source: this.filter.source,
                location: this.filter.location,
                create: this.filter.create,
                startTime: startTime,
                endTime: endTime
            };
            reqGetCustomer(para).then((res) => {
                this.loading = false;
                this.pageNum = res.data.data.current
                this.pages = res.data.data.pages
                this.total = res.data.data.total
                this.dataList = res.data.data.records;
            }).catch(() => {
                this.loading = false;
                this.dataList = [];
            });
        },
        getSource() {
            reqGetDictionaryListByCode({code:'customer_source'}).then((res)=>{
                this.sourceArray = res.data.data
            }).catch(()=>{
                this.sourceArray = []
            })
        },
        handleAdd() {
            this.showAddModal = true;
        },
        handleFollowUp(row, index) {
            this.id = row.id
            this.showFollowUpModal = true;
        },
        handleEdit(row, index) {
            this.id = row.id
            this.showEditModal = true;
        },
        handleView(row, index) {
            this.id = row.id
            this.showViewModal = true;
        },
        handleDel(row, index) {
            this.$Modal.confirm({
                title: '删除客户',
                content: '确定删除客户：'+row.name+'？此操作不可逆。',
                width: '320',
                onOk: () => {
                    reqDeleteCustomer({id: row.id}).then((res) => {
                        if (res.data.code === 1) {
                            this.getCustomer();
                            this.$Message.success('删除成功');
                        } else {
                            this.$Message.error('删除失败');
                        }
                    })
                },
            })
        },
        handleMark(row, index){
            this.id = row.id
            this.showMarkModal = true;
        },
        handleHandover(row, index){
            this.id = row.id
            this.ownerName = row.ownerName
            this.showHandoverModal = true;
        },
        //分页点击，切换页面
        changePage(val) {
            this.pageNum = val;
            this.getCustomer();
        },
        //每页显示的数据条数
        changPageSize(numOfRows) {
            //实时获取当前需要显示的条数
            this.pageSize = numOfRows;
            this.getCustomer();
        },
        getUserList(){
            reqGetUserList().then((res) => {
                this.createArray = res.data;
            }).catch(() => {
                this.createArray = [];
            })
        },
        doSearching() {
            this.pageNum = 1;
            this.getCustomer();
        }
    },
    mounted() {
        let permissionId = this.$route.query.permissionId
        permission.getPermissionButton(permissionId, this.permissionButton)
        this.userName = sessionStorage.getItem("un")
        this.getCustomer();
        this.getSource();
        this.getUserList();
    }
}
</script>
