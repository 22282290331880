<template>
    <Modal v-model="showForm" title="跟进客户" :mask-closable="false">
        <Form ref="followUpForm" :model="followUpForm" :rules="followUpFormRule" label-colon :label-width="90">
            <FormItem label="客户联系人" prop="contactId">
                <Select v-model="followUpForm.contactId" placeholder="请选择" filterable clearable>
                    <Option v-for="item in contactList" :value="item.id" :key="item.id">{{ item.name }}</Option>
                </Select>
            </FormItem>
            <FormItem label="跟进时间" prop="operationTime">
                <DatePicker v-model="followUpForm.operationTime" type="date" placeholder="请选择" :options="pastOptions" clearable></DatePicker>
            </FormItem>
            <FormItem label="跟进事宜" prop="details">
                <Input v-model="followUpForm.details" :maxlength="500" :autosize="{minRows: 2,maxRows: 5}" show-word-limit type="textarea" placeholder="请输入" />
            </FormItem>
        </Form>
        <div slot="footer">
            <Button type="default" @click="closeModal(false)">取消</Button>
            <Button type="primary" :loading="loading" @click="handleSubmit">确定</Button>
        </div>
    </Modal>
</template>
<script>
import {reqAddFollowUp, reqGetContactListByCustomerId} from "@/api/admin-api";

export default {
    name: "followCustomer",
    components: {},
    props: {
        value: {
            type: Boolean,
            default: false
        },
        id: '',
    },
    data() {
        return {
            loading: false,
            showForm: this.value,
            contactList: [],
            pastOptions: {
                disabledDate(date) {
                    return date && date.valueOf() > Date.now();
                }
            },
            followUpForm: {
                contactId: null,
                operationTime: null,
                details: '',
            },
            followUpFormRule: {
                contactId: [
                    {required: true, message: '请选择客户联系人', trigger: 'change'}
                ],
                operationTime: [{required: true, message: '请选择跟进时间', type: 'date', trigger: 'blur'}],
                details: [{required: true, message: '请输入跟进事宜', trigger: 'blur'}],
            },
        }
    },
    methods: {
        handleSubmit() {
            this.$refs['followUpForm'].validate((valid) => {
                if (valid) {
                    this.loading = true;
                    this.followUpForm.customerId = this.id
                    reqAddFollowUp(this.followUpForm).then((res)=>{
                        this.loading = false;
                        if (res.data.code === 1) {
                            this.$Message.success('添加成功');
                            this.closeModal(false);
                        }else {
                            this.loading = false;
                            this.$Message.error('添加失败');
                        }
                    }).catch(()=>{
                        this.loading = false;
                        this.$Message.error('添加失败');
                    })
                }
            });
        },
        getContactList(){
            reqGetContactListByCustomerId({id:this.id}).then((res)=>{
                if (res.data.code === 1){
                    this.contactList = res.data.data
                }else {
                    this.contactList = []
                }
            }).catch(()=>{
                this.contactList = []
            })
        },
        closeModal(val) {
            this.$emit('input', val);
        }
    },
    watch: {
        value(val) {
            this.showForm = val;
        },
        showForm(val) {
            //当重新显示增加数据的时候重置整个form表单
            if (val) {
                this.$refs['followUpForm'].resetFields();
                this.getContactList()
            } else {// 反之则关闭页面
                this.closeModal(val);
            }
        }
    }
}
</script>
