<template>
    <Modal v-model="showForm" title="编辑客户" :mask-closable="false">
        <Form ref="editForm" :model="editForm" :rules="editFormRule" label-colon :label-width="90">
            <FormItem label="信息来源" prop="source">
                <Select v-model="editForm.source" filterable clearable label-in-value>
                    <Option v-for="item in sourceArray" :value="item.id" :key="item.id">{{ item.name }}</Option>
                </Select>
            </FormItem>
            <FormItem label="客户名称" prop="name">
                <Input v-model.trim="editForm.name" :maxlength="50" placeholder="请输入" clearable/>
            </FormItem>
            <FormItem label="联系地址" prop="address">
                <Input v-model.trim="editForm.address" :maxlength="100" :autosize="{minRows: 2,maxRows: 5}" show-word-limit type="textarea" placeholder="请输入" />
            </FormItem>
            <FormItem label="所在地区" prop="location">
                <treeselect v-model="editForm.location"
                            :max-height="130"
                            :options="cityOpts"
                            :disable-branch-nodes="true"
                            placeholder="请选择"
                            loadingText="加载中..."
                            noOptionsText="暂无数据"
                            noResultsText="没有匹配数据"
                            @select="(node, instanceId) => handleCountySelected(node, instanceId, 'locationName')">
                    <div slot="value-label" slot-scope="{ node }">{{ node.raw.label }}</div>
                </treeselect>
            </FormItem>
            <div class="divider">客户联系</div>
            <div  class="custom-form">
                <div class="custom-form-section">
                    <div v-for="(obj, index) in editForm.contact" :class="index===0 ? 'custom-form-section-default' : 'custom-form-section-block divider-line'">
                        <Row :gutter="16">
                            <Col span="24">
                                <FormItem label="联系人" :prop="`contact.${index}.name`" :rules="editFormRule.contact">
                                    <Input type="text" clearable :maxlength="20" v-model="obj.name" placeholder="请输入"/>
                                </FormItem>
                                <FormItem label="联系人电话" :prop="`contact.${index}.phone`" :rules="editFormRule.contactNumber">
                                    <Input type="text" clearable :maxlength="13" v-model="obj.phone" placeholder="请输入"/>
                                </FormItem>
                                <FormItem label="联系人职务" :prop="`contact.${index}.title`">
                                    <Input type="text" clearable :maxlength="50" v-model="obj.title" placeholder="请输入"/>
                                </FormItem>
                            </Col>
                        </Row>
                        <Button class="remove-btn" type="text" size="small" v-if="index > 0" @click="removeContact(index)">- 移除</Button>
                    </div>
                    <div class="custom-form-section-btn">
                        <Button type="text" @click="addMoreContact">+ 添加更多联系人</Button>
                    </div>
                </div>
            </div>
        </Form>
        <div slot="footer">
            <Button type="default" @click="closeModal(false)">取消</Button>
            <Button type="primary" :loading="loading" @click="handleSubmit">确定</Button>
        </div>
    </Modal>
</template>
<script>
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import Treeselect from "@riophae/vue-treeselect";
import {checkPhone} from "@/common/publicFuns";
import {reqEditCustomer, reqGetCustomerById} from "@/api/admin-api";
import county from "@/assets/area/county";
import {reqGetDictionaryListByCode} from "@/api/system-api";
import city from "@/assets/area/city";

export default {
    name: "editCustomer",
    components: {Treeselect},
    props: {
        value: {
            type: Boolean,
            default: false
        },
        id: '',
    },
    data() {
        return {
            sourceArray: [],
            cityOpts: city,
            loading: false,
            showForm: this.value,
            editForm: {
                name: '',
                address: '',
                contact: [],
                deleteContact: [],
                source: null,
                location: null,
            },
            editFormRule: {
                source: [
                    {required: true, message: '请选择数据来源', type: 'integer', trigger: 'change'}
                ],
                location: [
                    {required: true, message: '请选择所在地区'}
                ],
                name: [
                    {required: true, message: '请输入客户名称', trigger: 'blur'}
                ],
                contact: [
                    {required: true, message: '请输入联系人', trigger: 'blur'}
                ],
                contactNumber: [{required: true, trigger: 'blur',
                    validator: (rule, value, callback) => {
                        if (!value) {
                            callback(new Error('请输入联系电话'));
                        }else if (!checkPhone(value)) {
                            return callback(new Error('联系电话不正确(座机格式\'区号-座机号码\')'));
                        }
                        return callback();
                    }
                }],
                address: [
                    {required: true, message: '请输入联系地址', trigger: 'blur'}
                ],
            },
        }
    },
    methods: {
        handleSubmit() {
            this.$refs['editForm'].validate((valid) => {
                if (valid) {
                    this.loading = true;
                    reqEditCustomer(this.editForm).then((res) => {
                        this.loading = false;
                        if (res.data.code === 1) {
                            this.$Message.success('编辑成功');
                            this.$emit('getCustomer');
                            this.closeModal(false);
                        }else {
                            this.loading = false;
                            this.$Message.error('编辑失败,客户名称重复');
                        }
                    }).catch(() => {
                        this.loading = false;
                        this.$Message.error('编辑失败');
                    });
                }
            });
        },
        addMoreContact() {
            this.editForm.contact.push({
                contact_1: '联系人', name: null,
                contact_2: '联系人电话', phone: null,
                contact_3: '联系人职务', title: null,
            });
        },
        removeContact(idx) {
            if (this.editForm.contact.length === 1){
                this.$Message.error("最低需要添加一个联系人")
            }else {
                if (this.editForm.contact[idx].id){
                    this.editForm.deleteContact.push(this.editForm.contact[idx].id)
                }
                this.editForm.contact.splice(idx, 1);
            }
        },
        getCustomerById() {
            reqGetCustomerById({id:this.id}).then((res)=>{
                this.editForm.source = res.data.data.customer.source
                this.editForm.location = res.data.data.customer.location
                this.editForm.locationName = res.data.data.customer.locationName
                this.editForm.id = res.data.data.customer.id
                this.editForm.name = res.data.data.customer.name
                this.editForm.address = res.data.data.customer.address
                this.editForm.contact = res.data.data.contacts
            }).catch(()=>{
                this.editForm = {}
            })
        },
        handleCountySelected(node, instanceId, propName) {
            this.editForm[propName] = node.label;
        },
        getSource() {
            reqGetDictionaryListByCode({code:'customer_source'}).then((res)=>{
                this.sourceArray = res.data.data
            }).catch(()=>{
                this.sourceArray = []
            })
        },
        closeModal(val) {
            this.$emit('input', val);
        }
    },
    watch: {
        value(val) {
            this.showForm = val;
        },
        showForm(val) {
            //当重新显示增加数据的时候重置整个form表单
            if (val) {
                this.$refs['editForm'].resetFields();
                this.editForm.contact = []
                this.editForm.deleteContact = []
                this.getCustomerById()
                this.getSource()
            } else {// 反之则关闭页面
                this.closeModal(val);
            }
        }
    }
}
</script>
