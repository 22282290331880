<template>
    <Modal title="查看客户" v-model="showForm" :width="640" :mask-closable="false">
        <Form class="plain-form" ref="viewForm" label-colon :label-width="90">
            <FormItem label="客户来源">{{ viewForm.sourceName }}</FormItem>
            <FormItem label="客户名称">{{ viewForm.name }}</FormItem>
            <FormItem label="联系地址">{{ viewForm.address }}</FormItem>
            <FormItem label="所在地区">{{ viewForm.locationName }}</FormItem>
            <div class="divider">客户联系</div>
            <div class="plain-form">
                <div class="plain-form-section">
                    <div class="plain-form-default-section" v-for="item in viewForm.contact">
                        <FormItem label="联系人">{{ item.name }}</FormItem>
                        <FormItem label="联系人电话">{{ item.phone }}</FormItem>
                        <FormItem label="联系人职位">{{ item.title }}</FormItem>
                    </div>
                </div>
            </div>
            <div class="divider">其他信息</div>
            <FormItem class="multiline-content" label="标注">{{viewForm.remark}}</FormItem>
            <FormItem label="负责人">{{viewForm.ownerName}}</FormItem>
            <FormItem label="创建人">{{ viewForm.createByName }}</FormItem>
            <FormItem label="创建时间">{{ viewForm.createTime }}</FormItem>
            <FormItem label="更新人">{{ viewForm.updatedByName }}</FormItem>
            <FormItem label="更新时间">{{ viewForm.updatedTime }}</FormItem>
            <div class="divider">跟进记录</div>
            <FormItem class="plain-item" label="时间线">
                <Timeline>
                    <TimelineItem v-for="item in followUpList" :value="item.id"
                                  :key="item.id">
                        <div class="status">{{ item.operationTime }}<span>{{ item.operatorName }} 联系客户 {{ item.contactName }}</span ></div>
                        <div class="operator">{{ item.details }}</div>
                    </TimelineItem>
                </Timeline>
            </FormItem>
        </Form>
        <div slot="footer">
            <Button type="primary" @click="closeModal(false)">确定</Button>
        </div>
    </Modal>
</template>

<script>
import {reqGetCustomerById, reqGetFollowUpList} from "@/api/admin-api";

export default {
    name: "viewCustomer",
    props: {
        value: {
            type: Boolean,
            default: false
        },
        id: ''
    },
    data() {
        return {
            followUpList: [],
            contactShow: false,
            showForm: false,
            viewForm: {},
        }
    },
    methods: {
        getCustomerById() {
            reqGetCustomerById({id:this.id}).then((res)=>{
                this.viewForm = res.data.data.customer
                this.viewForm.contact = res.data.data.contacts
                if (this.viewForm.contact.length > 0){
                    this.contactShow = true
                }
            }).catch(()=>{
                this.viewForm = {}
            })
        },
        getFollowUpList(){
            reqGetFollowUpList({id:this.id}).then((res)=>{
                if (res.data.code === 1){
                    this.followUpList = res.data.data
                }else {
                    this.followUpList = []
                }
            }).catch(()=>{
                this.followUpList = []
            })
        },
        closeModal(val) {
            this.$emit('input', val);
        },
    },
    watch: {
        value(val) {
            this.showForm = val;
        },
        showForm(val) {
            if (val) {
                this.getCustomerById();
                this.getFollowUpList();
            } else {// 反之则关闭页面
                this.closeModal(val);
            }
        }
    },
    mounted() {
    }
}
</script>
