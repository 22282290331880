<template>
    <Modal v-model="showForm" title="交接客户" :mask-closable="false">
        <Form ref="handoverForm" :model="handoverForm" :rules="handoverFormRule" label-colon :label-width="90">
            <FormItem label="当前负责人" prop="">
                {{ ownerName }}
            </FormItem>
            <FormItem label="负责人" prop="owner">
                <Select v-model="handoverForm.owner" placeholder="请选择" filterable clearable>
                    <Option v-for="item in userList" :value="item.id" :key="item.id">
                        {{ item.name }}
                    </Option>
                </Select>
            </FormItem>
        </Form>
        <div slot="footer">
            <Button type="default" @click="closeModal(false)">取消</Button>
            <Button type="primary" :loading="loading" @click="handleSubmit">确定</Button>
        </div>
    </Modal>
</template>
<script>
import {reqGetCustomerById, reqHandoverCustomer} from "@/api/admin-api";
import {reqGetUserList} from "@/api/system-api";

export default {
    name: "handoverCustomer",
    components: {},
    props: {
        value: {
            type: Boolean,
            default: false
        },
        id: '',
        ownerName: '',
    },
    data() {
        return {
            userList: [],
            loading: false,
            showForm: this.value,
            handoverForm: {
                owner: ''
            },
            handoverFormRule: {
                owner: [
                    {required: true, message: '请选择负责人', trigger: 'change'}
                ]
            },
        }
    },
    methods: {
        handleSubmit() {
            this.$refs['handoverForm'].validate((valid) => {
                if (valid) {
                    this.loading = true;
                    reqHandoverCustomer(this.handoverForm).then((res) => {
                        this.loading = false;
                        if (res.data.code === 1) {
                            this.$Message.success('交接成功');
                            this.$emit('getCustomer');
                            this.closeModal(false);
                        }
                    }).catch(() => {
                        this.loading = false;
                        this.$Message.error('交接失败');
                    });
                }
            });
        },
        getCustomerById() {
            reqGetCustomerById({id:this.id}).then((res)=>{
                this.handoverForm = res.data.data.customer
            }).catch(()=>{
                this.handoverForm = {}
            })
        },
        getUserList(){
            reqGetUserList().then((res) => {
                this.userList = res.data
            }).catch(() => {
                this.userList = []
            })
        },
        closeModal(val) {
            this.$emit('input', val);
        }
    },
    watch: {
        value(val) {
            this.showForm = val;
        },
        showForm(val) {
            //当重新显示增加数据的时候重置整个form表单
            if (val) {
                this.$refs['handoverForm'].resetFields();
                this.getCustomerById()
                this.getUserList()
            } else {// 反之则关闭页面
                this.closeModal(val);
            }
        }
    }
}
</script>
